<template>
    <loader v-bind="{ loading }">
        <columns>
            <column>
                <page-heading :heading="equipment_classification.name"></page-heading>
            </column>
            <column class="is-narrow">
                <tabs>
                    <tab-link name="equipment-classification-manager" :params="{ equipmentClassification: equipment_classification.uuid }">Overview</tab-link>
                    <tab-link name="equipment-classification-equipment" :params="{ equipmentClassification: equipment_classification.uuid }">Equipment List</tab-link>
                    <tab-link name="edit-equipment-classification" :params="{ equipmentClassification: equipment_classification.uuid }">Edit</tab-link>
                </tabs>
            </column>
        </columns>
        <router-view />
    </loader>
</template>
<script>
import { mapGetters } from 'vuex'

export default {

    data: () => ({
        loading: true
    }),

    async beforeCreate() {
        await this.$store.dispatch('equipmentClassification/load', this.$route.params.equipmentClassification)
        this.loading = false
    },

    beforeDestroy() {
        this.$store.commit('equipmentClassification/clearEquipmentClassification')
    },

    computed: mapGetters('equipmentClassification', [
        'equipment_classification'
    ])

}
</script>